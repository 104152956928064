
function Equipment() {

  const scrollTo = (id) => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };


    return (
      <div className="Equipment">

        <div className="relative h-80">
            <img className="mx-auto h-full w-full object-cover" src="/images/equipment-banner.jpg" alt="Drilling Equipment Banner" />
            <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white ">Equipment</h1>
        </div>

        <div className="grid-container">

          <div className="relative col-span-full md:col-start-1 md:col-span-2">
            <div className="sticky top-[110px] my-10 py-5 md:py-12 px-3 bg-gray-100 border-2 border-solid border-black rounded-lg">
              <h3 className="hover:underline py-2 cursor-pointer" onClick={() => scrollTo('rig1')}>Rig 1</h3>

              <h3 className="hover:underline py-2 cursor-pointer" onClick={() => scrollTo('rig2')}>Rig 2</h3>
            </div>
          </div>

          <div id="rig1" className="col-span-4 md:col-span-8 col-start-1 md:col-start-4">
            <div className="py-8">
              <h2 className="pb-3 text-left text-blacks font-bold">Rig 1 - 2022 Hanjin D&B 8D</h2>
              <p className="pb-2 text-left">
                Rig 1 is a powerful and versatile multipurpose rig. 
                With rubber tracks it can access the most difficult sites with very little footprint.
              </p>
              <p className="pb-2 text-left">Dimensions:</p>
              <ul className="pb-2 pl-5 text-left list-disc">
                <li>Width - 2.4m</li>
                <li>Length - 6m</li>
                <li>Height - 2.2m/6m</li>
                <li>Weight - 6000kg </li>
              </ul>
            </div>
            <div className="pb-5 flex flex-col md:flex-row gap-5">
              <img className="mx-auto w-[300px]" src="/images/drill-rig-1.jpg" alt="Drill Rig angle 1" />
              <img className="mx-auto w-[300px]" src="/images/drill-rig-3.jpg" alt="Drill Rig angle 3" />
            </div>
          </div>

          <div id="rig2" className="col-span-4 md:col-span-8 col-start-1 md:col-start-4">
            <div className="py-8">
              <h2 className="pb-3 text-left text-blacks font-bold">Rig 2 - 2024 Hanjin D&B 8TM</h2>
              {/* <p className="pb-2 text-left">
                Rig 1 is a powerful and versatile multipurpose rig. 
                With rubber tracks it can access the most difficult sites with very little footprint.
              </p>
              <p className="pb-2 text-left">Dimensions:</p>
              <ul className="pb-2 pl-5 text-left list-disc">
                <li>Width - 2.4m</li>
                <li>Length - 6m</li>
                <li>Height - 2.2m/6m</li>
                <li>Weight - 6000kg </li>
              </ul> */}
            </div>
            {/* <div className="pb-5 flex flex-col md:flex-row gap-5">
              <img className="mx-auto w-96" src="/images/drill-rig-1.jpg" alt="Drill Rig angle 1" />
              <img className="mx-auto w-96" src="/images/drill-rig-3.jpg" alt="Drill Rig angle 3" />
            </div> */}
          </div>
        </div>



      </div>
    );
  }
  
  export default Equipment;

