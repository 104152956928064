import { Link } from "react-router-dom";

function Header(props) {

  const {HamburgerMenu, toggled, toggle} = props;

  return (
    <div className="Header sticky top-0 py-3 shadow-lg bg-black text-white z-50">
      <div className="flex flex-row items-center justify-between mx-8 my-4">
        <div className="w-1/2 md:w-1/5">
          <Link to="/">
            <img src="/images/logo-inverse.png" alt="Haddad Drilling Logo" />
          </Link>
        </div>

        <div className="block md:hidden">
         <HamburgerMenu aria-label="Opening menu navigation" color="#ffd700" toggled={toggled} toggle={toggle} />
        </div>

        <div className="hidden md:flex md:flex-row md:gap-5 font-bold ">
          <Link className="link link-underline link-underline-yellow hover:text-primary" to="/services">
            Services
          </Link>

          <Link className="link link-underline link-underline-yellow hover:text-primary" to="/equipment">
            Equipment
          </Link>

          <Link className="link link-underline link-underline-yellow hover:text-primary" to="/contact-us">
            Contact Us
          </Link>

        </div>

      </div>


    </div>
  );
}

export default Header;
