function Home() {

  return (
    <div className="Home">
        <div className="p-6 bg-gray-100 shadow-md">
        <h2 className="text-black text-center">Welcome to the Haddad Drilling Admin Portal</h2>
      </div>
    </div>
  );
  }
  
  export default Home;
  