import { GrDocumentWord } from "react-icons/gr";
import { useState, useEffect } from "react";
import { getUrl } from 'aws-amplify/storage';
import { fetchAuthSession } from 'aws-amplify/auth';

function Swms() {

  const [swmsFileData, setSwmsFileData] = useState();

  useEffect(() => {
    const getSwmsDocument = async () => {
      const credentials = await fetchAuthSession();

      const checkUrl = await getUrl({
        key: 'SWMS.docx',
        options: {
          validateObjectExistence: true // defaults to false
        }
      });

      console.log(' ', checkUrl)
    
      // const getUrlResult = await getUrl({
      //   key: 'SWMS.docx',
      //   options: {
      //     accessLevel: 'private',
      //     targetIdentityId: credentials.identityId, 
      //     validateObjectExistence: false,
      //     expiresIn: 3600,
      //     useAccelerateEndpoint: true // Whether to use accelerate endpoint.
      //   },
      // });
      
      // setSwmsFileData(getUrlResult)
    }
    getSwmsDocument();
  }, []);


  return (
    <div className="Swms">
      <div className="p-6 mb-10 bg-gray-100 shadow-md">
        <h2 className="text-black text-center">SWSM Document</h2>
      </div>

      <div className="files flex justify-center">
        <a className="flex items-center gap-2 text-lg" href="https://haddaddrillingsafteydocs132750-dev.s3.ap-southeast-2.amazonaws.com/Drilling-SWMS.docx" target="_blank" rel="noreferrer">
          <GrDocumentWord />
          Download SWSM Document
        </a>
      </div>

      <div className="files flex justify-center">
        <a href={swmsFileData?.url?.href ? swmsFileData.url.href : ''} target="_blank" rel="noreferrer">
          File Name
        </a>;
      </div>
    </div>
  );
  }
  
  export default Swms;
  