import { CSSTransition } from 'react-transition-group';
import { Link } from "react-router-dom";
import './MobileMenu.css'

function MobileMenu(props){
    return(
        <CSSTransition
            in={props.toggled}
            timeout={1000}
            classNames="menu"
            unmountOnExit
        >
            <div className="fixed flex flex-col justify-center w-full bg-black h-screen overflow-hidden text-white z-40">
                <Link className="link link-underline link-underline-yellow block py-5 text-2xl font-bold text-center hover:text-primary" to="/services">
                    Services
                </Link>

                <Link className="link link-underline link-underline-yellow block py-5 text-2xl font-bold text-center hover:text-primary" to="/equipment">
                    Equipment
                </Link>

                <Link className="link link-underline link-underline-yellow block py-5 text-2xl font-bold text-center hover:text-primary" to="/contact-us">
                    Contact Us
                </Link>
            </div>
        </CSSTransition>
    )
}

export default MobileMenu