import { Link } from "react-router-dom";

function Button(props) {
    return (
        <button aria-label="button" className="rounded px-5 py-3 m-10 bg-primary hover:bg-black text-black hover:text-white uppercase font-bold">
            <Link to={props.link}>
                { props.text }
            </Link>
        </button>
    );
}
  
export default Button;
  