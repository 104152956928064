import React, { useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { createCandidate } from '../../graphql/mutations'

function ContactForm() {

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  })

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [formSuccessMessage, setFormSuccessMessage] = useState('')


  const handleContactFormSubmit = async (e) => {
    e.preventDefault()
    const { name, email, phoneNumber, message } = formState
    if (name && email && phoneNumber && message) {
      try {
        const client = generateClient({authMode: 'apiKey'});

        await client.graphql({
          query: createCandidate,
          variables: {
            input: {
              name,
              email,
              phoneNumber, 
              message
            },
          },
        })

        setFormState({
            name: '',
            email: '',
            phoneNumber: '',
            message: ''
        })

        setFormSuccessMessage('Thank you for your enquiry. We will be in touch shortly.')

        setFormErrorMessage('')
      } catch (e) {
        console.log(e)
        setFormErrorMessage(`There was an error submitting your form, please come back later or call us on <a className="underline" href="tel:0427527624">0427 527 624</a> or via email on <a className="underline" href="mailto:cameron@haddaddrilling.com.au">cameron@haddaddrilling.com.au</a>`)
      }
    } else {
      setFormErrorMessage('Please complete all fields')
    }
  }

  return (
    <div className="ContactForm px-4">
        <form className="flex flex-col gap-5 text-left" encType="multipart/form-data" name="ContactForm" onSubmit={handleContactFormSubmit}>
          <div className="flex flex-col">
            <label className="mb-2" htmlFor="name">Name*</label>
            <input 
              name="from_name" 
              className="border border-secondary rounded p-1" 
              type="text" 
              placeholder="Your Name"
              value={formState.name}
              onChange={(e) =>
                setFormState({ ...formState, name: e.target.value })
              }/>
          </div>
          <div className="flex flex-col">
            <label className="mb-2" htmlFor="email">Email*</label>
            <input 
              name="email" 
              className="border border-secondary rounded p-1" 
              type="text" 
              placeholder="Your Email Address" 
              value={formState.email}
              onChange={(e) =>
                setFormState({ ...formState, email: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2" htmlFor="phonenumber">Contact Number*</label>
            <input 
              name="number" 
              className="border border-secondary rounded p-1" 
              type="number" 
              placeholder="Your Phone Number" 
              value={formState.phoneNumber}
              onChange={(e) =>
                setFormState({ ...formState, phoneNumber: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2" htmlFor="message">Additional Information*</label>
            <textarea 
              name="message" 
              placeholder='Please tell us how we can help'
              className="border border-secondary rounded p-1" 
              cols="5" 
              rows="5" 
              value={formState.message}
              onChange={(e) =>
                setFormState({ ...formState, message: e.target.value })
              }
            />
          </div>
          <div className="font-bold text-red-700" dangerouslySetInnerHTML={{ __html: formErrorMessage }} />
          <div className="font-bold" dangerouslySetInnerHTML={{ __html: formSuccessMessage }} />

          <input className="rounded w-full md:w-1/4 px-0 md:px-5 py-3 m-0 md:mx:10 mb-10 bg-primary hover:bg-black text-black hover:text-white uppercase font-bold" type="submit" value="Submit" />
        </form>

    </div>
  );
}
  
export default ContactForm;
  