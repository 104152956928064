function Services() {
    return (
      <div className="Services grid-container py-5">
        <div className="col-span-full my-5"><h1>Services</h1></div>
        <div className="flex items-center flex-col md:flex-row justify-around col-span-4 md:col-span-10 col-start-1 md:col-start-2">
          <div class="mr-10">
            <ul className="pl-5 text-left list-disc">
              <li>Solid flight augers</li>
              <li>Hollow stem augers</li>
              <li>Mud Rotary (Washbore)</li>
              <li>Rotary Air (RAB)</li>
              <li>Casing advancing (HQ & HWT)</li>
              <li>Coring (Conventional & Wireline)</li>
              <li>Concrete coring</li>
              <li>SPT testing</li>
              <li>U50,63,75 Tubes</li>
              <li>Monitoring Well installation</li>
              <li>Inclinometer installation</li>
              <li>Packer Testing - Single or Straddle</li>
            </ul>

            <h2 class='mt-5'>Plant Hire</h2>
            <ul className="pl-5 text-left list-disc">
              <li>Excavator</li>
              <ul>
                <li>Test pits</li>
                <li>Site access</li>
                <li>Drill pad preparation</li>
              </ul>
              <li>Hydro vac/NDD</li>
              <ul>
                <li>Potholing</li>
              </ul>
            </ul>
          </div>
          <img className="mt-5 md:mt-0 w-full md:w-1/2" src="/images/drill-services.jpg" alt="Drill rig working services" />
        </div>

      </div>
    );
  }
  
  export default Services;
  