function PlantRiskAssesment() {

  return (
    <div className="PlantRiskAssesment">
      <div className="p-6 bg-gray-100 shadow-md">
        <h2 className="text-black text-center">SWMS</h2>
      </div>
    </div>  
  );
  }
  
  export default PlantRiskAssesment;
  