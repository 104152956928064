import { useState } from "react";

import {
  Sidebar,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import * as Auth from 'aws-amplify/auth'
import '@aws-amplify/ui-react/styles.css';   

import { FiArrowRightCircle } from "react-icons/fi";
import { HiMiniXMark } from "react-icons/hi2";

import '../Styles/authenticator.css'

import PlantRiskAssesment from "../Components/Admin-Components/PlantRiskAssesment";
import Home from "../Components/Admin-Components/Home";
import Swms from '../Components/Admin-Components/Swms'

const components = {
  Header() {  
  return (
      <div>
          <br/>
      </div>
  );
  },
  Footer() {
  return (
      <br/>
  );
  },
  SignIn: {
  Header() {
      return (
          <h1>Login to your account</h1>
      );
  },
  Footer() {
      const { toForgotPassword } = useAuthenticator();
      return (
      <div>
          <button
          onClick={toForgotPassword}
          className="py-4"
          >
          Reset Password
          </button>
      </div>
      );
  },
  },
  SetupTotp: {
  Header() {
      return (
      <div
      >
          2 Step Verification
      </div>
      );
  },
  //   Footer() {
  //     return <div>
  //     </div>;
  //   },
  },
  ConfirmSignIn: {
  Header() {
      return (
      <div
      >
          2 Step Verification
      </div>
      );
  },
  //   Footer() {
  //     return <div>Footer Information</div>;
  //   },
  },
  ForgotPassword: {
  Header() {
      return (
      <div
      >
          Enter Information:
      </div>
      );
  },
  //   Footer() {
  //     return <div>Footer Information</div>;
  //   },
  },
  ConfirmResetPassword: {
  Header() {
      return (
      <div
      >
          Enter Information:
      </div>
      );
  },
  //   Footer() {
  //     return <div>Footer Information</div>;
  //   },
  },
};

const formFields = {
  signIn: {
  username: {
      placeholder: 'Enter your email address',
  },
  },
  forceNewPassword: {
  password: {
      placeholder: 'Enter your Password:',
  },
  },
  forgotPassword: {
  username: {
      placeholder: 'Enter your email:',
  },
  },
  confirmResetPassword: {
  confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
  },
  confirm_password: {
      placeholder: 'Enter your Password Please:',
  },
  },
  setupTotp: {
  QR: {
      totpIssuer: 'Haddad Drilling Admin',
      totpUsername: '',
  },
  confirmation_code: {
      placeholder: 'Enter your 2 Step Verification Code:',
      isRequired: false,
  },
  },
  confirmSignIn: {
  confirmation_code: {
      label: 'Enter your 2 Step Verification Code:',
      placeholder: 'Code',
      isRequired: false,
  },
  },
};

function Admin() {

    const { route } = useAuthenticator(context => [context.route]);
    let iconStyles = { fontSize: "1.5em" };
    const [menuCollapse, setMenuCollapse] = useState(true);
    const [pageContent, setPageContent] = useState('home')


    const menuIconClick = () => {
      menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    const changePage = (menuItem) => {
        setPageContent(menuItem);
        setMenuCollapse(true)
    }

    return route === 'authenticated' ? 
      <div className="flex bg-gr">
        <Sidebar collapsed={menuCollapse}>
            <div className={`closemenu flex ${menuCollapse ? "justify-center" : "justify-end pr-5"} pt-5`} onClick={menuIconClick}>
              {menuCollapse ? (
                <FiArrowRightCircle style={iconStyles} />
              ) : (
                <HiMiniXMark style={iconStyles}/>
              )}
            </div>
            <Menu iconShape="square mx-20">
              <MenuItem className={`my-2 ${pageContent === 'home' ? "bg-gray-700 text-white hover:text-black" : ""}`} onClick={() => changePage('home')} active={true}>
                Home
              </MenuItem>
              <MenuItem className={`my-2 ${pageContent === 'swms' ? "bg-gray-700 text-white" : ""}`} onClick={() => changePage('swms')}>SWMS</MenuItem>
              <MenuItem onClick={() => changePage('plant-risk-assesment')}>Plant Risk Assesment</MenuItem>
              <MenuItem onClick={() => changePage('sds')}>SDS</MenuItem>
              <MenuItem onClick={() => changePage('incident-report')}>Incident Report</MenuItem>
              <MenuItem onClick={() => changePage('hazard-report')}>Hazard Report</MenuItem>
              <MenuItem onClick={() => changePage('toolbox')}>Toolbox</MenuItem>
              <MenuItem onClick={() => changePage('prestart')}>PreStart</MenuItem>
            </Menu>
            <Menu className="pb-5" iconShape="square">
              <MenuItem onClick={() => Auth.signOut()}>Logout</MenuItem>
            </Menu>
        </Sidebar>        

        <div className="page-content relative w-full bg-gray-200">
            {
                pageContent === 'swms' ? <Swms/> :
                pageContent === 'plant-risk-assesment' ? <PlantRiskAssesment /> 
                : <Home/>
            }

            <div className={`${menuCollapse ? 'hidden' : 'block'} absolute top-0 left-0 h-full w-full bg-black opacity-50`}/>
        </div>


      </div> :
      <Authenticator formFields={formFields} hideSignUp={true} components={components} />;

  }
  
  export default Admin;
  



        

    

        