
function Footer() {
    return (
      <div className="Footer py-3 w-full bg-black shadow-top-lg text-white">
          <div className="flex flex-col items-center justify-between mx-5 my-4">
            <div className="w-1/2 md:w-1/5 mb-2">
              <img src="/images/logo-inverse.png" alt="Haddad Drilling Logo" />
            </div>
            <div>
              <div className="my-2">
                <a href="tel:0427527624">
                  <img className="w-6 mx-auto my-4" src="/icons/telephone.png" alt="Phone Icon" />
                  <div>0427 527 624</div>
                </a>
              </div>

              <div>
                <a href="mailto:cameron@haddaddrilling.com.au">
                  <img className="w-6 m-auto my-4" src="/icons/email.png" alt="Email Icon" />
                  <div>cameron@haddaddrilling.com.au</div>
                </a>
              </div>
            </div>
          </div>
      </div>
    );
  }
  
  export default Footer;
  