import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Hamburger from 'hamburger-react'
import { Amplify } from 'aws-amplify';
import config from './aws-exports'

// Styles
import './App.css';

// Pages
import Home from './Pages/Home';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Equipment from "./Pages/Equipment";
import Admin from "./Pages/Admin"

// Components
import Header from './Components/Global-Components/header/Header';
import Footer from './Components/Global-Components/footer/Footer';
import MobileMenu from './Components/Global-Components/mobile-menu/MobileMenu'

Amplify.configure(config);

function App() {

  let location = useLocation()
  const [isOpen, setOpen] = useState(false)

  useEffect( () => {
    window.scrollTo(0, 0);
    setOpen(false)
  }, [location])

  return (
    <div className="App flex flex-col justify-between min-h-screen">

      <Header HamburgerMenu={Hamburger} toggled={isOpen} toggle={setOpen} />
      <MobileMenu toggled={isOpen} />
        
      <div className="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="equipment" element={<Equipment />} />
          <Route path="admin" element={<Admin />} />
        </Routes>
      </div>


      <Footer/>

    </div>
  );
}

export default App;
