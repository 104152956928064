import ContactForm from "../Components/General-Components/ContactForm";

function Contact() {

  return (
    <div className="Contact my-10">
      <div className="max-w-xl mx-auto my-10">
        <h1>Contact Us</h1>
        <div>
          Get in touch with us! <br/> Call us on <a className="font-bold underline" href="tel:0427527624">0427 527 624</a> or via email on <a className="font-bold underline" href="mailto:cameron@haddaddrilling.com.au">cameron@haddaddrilling.com.au</a>
        </div>
      </div>

      <div className="grid-container">
        <div className="col-span-4 md:col-span-8 col-start-1 md:col-start-3">
          <ContactForm/>
        </div>
      </div>
    </div>
  );
  }
  
  export default Contact;
  