// Components
import Button from "../Components/General-Components/Button.js";


function Home() {
    return (
      <div className="Home">
        <div className="relative h-80">
          <img className="mx-auto h-full w-full object-cover" src="/images/home-june24-resized.jpg" alt="Drilling Equipment Banner"/>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
              <h1 className="text-white text-6xl md:text-7xl [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">Haddad Drilling</h1>
              <button aria-label="button" className="rounded px-5 py-3 m-10 bg-primary hover:bg-black text-black hover:text-white uppercase font-bold !mt-2">
                <a href="/contact-us">Contact Us</a>
              </button>
            </div>
          </div>
        <div className="max-w-xl mx-auto mt-12">
          <div className="m-4">
            Haddad Drilling is a family owned and operated company founded by Cameron and William Haddad to service the environmental, geotechnical and mineral exploration industries. 
            <br/> <br/>
            With 10 years experience in the drilling industry you can rely on Haddad Drilling to provide the upmost quality, safety and flexibility to suit any site investigation or project. 
            <br/><br/>
            Based in Hume, ACT and servicing the Eastern Seaboard of Australia with state of the art equipment we offer a wide range of services to cater for all our clients needs. 
            <br/><br/>
            Fill out our enquiry form or get in touch today to discuss all your drilling needs!
            </div>
            <div>
              Call us on <a className="font-bold" href="tel:0427527624">0427 527 624</a> or via email on <a className="font-bold" href="mailto:cameron@haddaddrilling.com.au">cameron@haddaddrilling.com.au</a>
          </div>
          <Button link="/contact-us" text="Contact Us"/>
        </div>
        
      </div>
    );
  }
  
  export default Home;
  